/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Inject, Injectable } from '@angular/core';

import { RiderMessagesApiService } from './rider-messages-api.service';
import { LoggerService } from '@cubicNx/libs/utils';
import { NotificationService } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';
import { ResultContent } from '@cubicNx/libs/utils';

import { STOPS_FOR_ROUTE_STORE } from './rider-messages-store-factory';

import { StopsForRoute } from '../types/types';

import {
	RiderMessageResponse,
	RiderMessage,
	RiderMessages,
	RiderMessagesPaginatedResponse,
	RiderMessagesPaginatedParams,
} from '../types/api-types';

@Injectable()
export class RiderMessagesDataService {
	constructor(
		private logger: LoggerService,
		private translationService: TranslationService,
		private notificationService: NotificationService,
		private riderMessagesApiService: RiderMessagesApiService,
		@Inject(STOPS_FOR_ROUTE_STORE) private stopsForRouteDataStore: any
	) {}

	/**
	 * handles the request for the deletion of a rider message(s) for the authority/agency, rider message ids from the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @param agencyId - the agency id
	 * @param serviceAlertIds - the rider message ids being deleted
	 * @returns response containing result of the delete attempt
	 */
	public deleteMessages = async (serviceAlertIds: string[], authorityId: string, agencyId: string): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const requests: Promise<any>[] = serviceAlertIds.map(async (serviceAlertId) =>
				this.riderMessagesApiService.deleteRiderMessage(authorityId, agencyId, serviceAlertId)
			);

			await Promise.all(requests);

			result.success = true;

			this.notificationService.notifySuccess(this.translationService.getTranslation('T_CORE.DELETE_SUCCESS'));
		} catch (error: any) {
			this.logger.logError('Failed to delete rider messages', error);

			if (error.status === 403) {
				this.notificationService.notifyError(this.translationService.getTranslation('T_CORE.NO_PERMISSION'));
			} else {
				this.notificationService.notifyError(this.translationService.getTranslation('T_CORE.SERVER_ERROR'));
			}

			return error;
		}

		return result;
	};

	/**
	 * handles the request for the creation of a rider message for the authority/agency rider message data from the nextbus API
	 *
	 * @param entity - the rider message entity being created
	 * @returns response containing result of the creation attempt
	 */
	public createMessage = async (entity: RiderMessage): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const response: any = await this.riderMessagesApiService.createRiderMessage(entity.authority_id, entity.agency_id, entity);

			result.success = true;
			result.resultData = response as RiderMessageResponse;

			if (response.success) {
				this.notificationService.notifySuccess(this.translationService.getTranslation('T_RIDER.CREATE_SUCCESS'));
			} else {
				this.notificationService.notifyError(this.translationService.getTranslation('T_RIDER.CREATE_FAILURE'));
			}
		} catch (error: any) {
			this.notificationService.notifyError(error.data.error.message);
		}

		return result;
	};

	/**
	 * handles the request for the editing of a rider message for the authority/agency, rider message id and
	 * edited rider message data from the nextbus API
	 *
	 * @param serviceAlertId - the ridermessage id being edited
	 * @param message - the edited rider message
	 * @returns response containing result of the edit attempt
	 */
	public editMessage = async (serviceAlertId: string, message: RiderMessage): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const response: any = await this.riderMessagesApiService.editRiderMessage(
				message.authority_id,
				message.agency_id,
				serviceAlertId,
				message
			);

			result.success = true;
			result.resultData = response as RiderMessageResponse;

			if (response.success) {
				this.notificationService.notifySuccess(this.translationService.getTranslation('T_RIDER.UPDATE_SUCCESS'));
			} else {
				this.notificationService.notifyError(this.translationService.getTranslation('T_RIDER.UPDATE_FAILURE'));
			}
		} catch (error: any) {
			this.logger.logError('Failed to edit message - ' + JSON.stringify(error));
			this.notificationService.notifyError(this.translationService.getTranslation('T_RIDER.UPDATE_FAILURE'));
		}

		return result;
	};

	/**
	 * requests the rider message for the given authority, message id from the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @param messageId - the message id
	 * @returns response containing rider message
	 */
	public getRiderMessage = async (authorityId: string, messageId: string): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const response: any = await this.riderMessagesApiService.getRiderMessage(authorityId, messageId);

			result.success = true;
			result.resultData = response[0] as RiderMessages;
		} catch (error: any) {
			this.logger.logError('Failed to get rider message, messageId: ' + messageId, error);
		}

		return result;
	};

	/**
	 * handles the request for the rider messages for the given authority/agency and parameters from the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @param agencyId - the agency id
	 * @param params - the retrieve parameters
	 * @returns response containing rider messages
	 */
	public getMessages = async (authorityId: string, agencyId: string, params: RiderMessagesPaginatedParams): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const response: any = await this.riderMessagesApiService.getRiderMessages(authorityId, agencyId, params);

			result.success = true;

			// The backend typically returns a paged object including results and totals but can just return an array with
			// the dataset if the total is less than the page size. Ensure we return the response in the same format either way
			if (response.results) {
				// Data is a paginated response
				result.resultData = response as RiderMessagesPaginatedResponse;
			} else if (Array.isArray(response)) {
				const paginatedResponse: RiderMessagesPaginatedResponse = {
					results: response,
					total: response.length,
					totalPages: 1,
				};

				result.resultData = paginatedResponse as RiderMessagesPaginatedResponse;
			}
		} catch (error: any) {
			this.logger.logError('Failed to get service alerts (rider messages) - ', error);
			this.notificationService.notifyError(this.translationService.getTranslation('T_CORE.SERVER_ERROR'));
		}

		return result;
	};

	/**
	 * handles the request for the stops by route for the given authority, route from the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @param routeId - the route id
	 * @returns response containing stops by route
	 */
	public getStopsByRoute = async (authorityId: string, routeId: string): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const storeKey: string = authorityId + '-' + routeId;

			let stopsForRoute: StopsForRoute = this.stopsForRouteDataStore.get(storeKey);

			if (!stopsForRoute) {
				this.logger.logDebug(`Requesting stopsForRoute for: ${storeKey}`);

				stopsForRoute = await this.riderMessagesApiService.getStopsByRoute(authorityId, routeId);

				this.stopsForRouteDataStore.set(storeKey, stopsForRoute);
			} else {
				this.logger.logDebug(`Returning stopsForRoute for: ${storeKey} from cached store`);
			}

			result.success = true;
			result.resultData = stopsForRoute as StopsForRoute;
		} catch (error: any) {
			this.logger.logError('Failed to get stops by route for authority: ' + authorityId + ', route: ' + routeId, error);
		}

		return result;
	};
}
